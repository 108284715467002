import React, { useState, useCallback } from "react";
import { Button, FormGroup, FormControl, FormLabel  } from "react-bootstrap";
import "./Login.css";
import styles from '../shared/header.module.scss';
import auth from '../../services/auth';
import * as Services from '../../services/user.service';

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginerror, setLoginerror] = useState('');
  const [logging, setLogging] = useState('');

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  /*function handleSubmit(event) {
    event.preventDefault();
  }*/

  const handleSubmit = useCallback(
    async (event: SyntheticEvent<>) => {
      event && event.preventDefault();

      try {

        const params = {username: username, password: password}

        setLogging(true);

        let loginok = false;

        await Services.user.login(null, params).then((res) => {

            setLogging(false);
            if (res.data && res.data.id) {

              auth.setToken(res.data.id);
              auth.setUserInfo(res.data);
              loginok = true;

            } else {

                setLoginerror('Login Failed!');
            }
        }).catch((err) => {
      
            setLoginerror('Login Failed!');
            setLogging(false);
        });

        if(loginok) {

         setLogging(true);

          let userinfo = auth.getUserInfo();
          console.log(userinfo);

         await Services.user.get(null, {id: userinfo.userId}).then((res) => {

              setLogging(false);
              if (res.data && res.data.id) {

                userinfo = res.data;

                if(userinfo.officeCode) {

                  auth.setUserInfo(userinfo);      
                  let cartItems = res.data.cartItems;
                  let cartNotes = res.data.cartNotes;
                  console.log(res.data)
                  localStorage.setItem('cart', (cartItems ? cartItems: []));    
                  localStorage.setItem('cartNotes', (cartNotes ? cartNotes: ""));  
                  document.location = "/";
                } else {

                  setLoginerror('User account is not set for an office login!');
                }
                //history.push({ pathname: '/' }); // back to Home 
              } else {

                  setLoginerror('Login Failed!');
              }
          }).catch((err) => {
        
              setLoginerror('Login Failed');
              setLogging(false);
          });

        } 

      } catch (error) {
        /* eslint-disable no-console */
        console.log('login went wrong..., error: ', error);
        /* eslint-enable no-console */
      }
    },
    [username, password],
  );

  return (
    <div className="Login">
      <div className={styles.headerwrapper + " col-sm-12 text-left pb-3 headpanel2"} >
        <div className={styles.logo}  style={{"max-height": "70px"}}>
          <img src={"logo2.png"} width={"200px"} /> <span className={styles.headertext}>Staff Ordering</span>                    
        </div>
      </div>
      <form onSubmit={handleSubmit}>
      <h2>Login</h2>
        <FormGroup controlId="username" bsSize="large">
          <FormLabel >Username</FormLabel >
          <FormControl
            autoFocus
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormLabel >Password</FormLabel >
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm()} type="submit">
          {logging ? (
            <span>
              Logging in...
              <i className="fa fa-spinner fa-pulse fa-fw" />
            </span>
          ) : (
            <span>Login</span>
          )}
        </Button>
         {loginerror && (
            <h5 className="pt-3">
              <center>
                <span class='text-danger text-center'>{loginerror}</span>
              </center>
            </h5>
          )}
      </form>
    </div>
  );
}