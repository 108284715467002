import React from "react";
import Api from '../utils/Http'

import { Link } from "react-router-dom";

export const order = {
    retrieveList : (store, options = {}) => {
        let path = "/orders?filter[include][orderDetail]=product";

        if (options.officeCode) {
            path += "&filter[where][officeCode]=" + options.officeCode;
        }

        path += "&filter[order]=orderNo DESC";

        return Api.get(path);
    },

    add: (store, options = {}) => {
        return Api.post('/orders', options);
    },

    sendEmail: (store, options = {}) => {
        return Api.post('/orders/send-email', options);
    },

    patchItem: (store, options = {}) => {

        return Api.patch('/orderDetails/' + options.id, options);
    }

}