import React from "react";
import Api from '../utils/Http'

import { Link } from "react-router-dom";

export const inventory = {
    retrieveList: (store, options = {}) => {

        let path = "/inventories?filter[include]=product";

        if (options.location) {
            path += "&filter[where][location]=" + options.location;
        }

        return Api.get(path);
    }
}