import React, { useContext, useState } from 'react';
import Layout from '../Layout';

import { Modal } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner'
import * as Services from '../../services/user.service';
import OrdersGrid from './OrdersGrid';
import CurrentCartItems from '../common/CurrentCartItems';

const Order = () => {    

    CurrentCartItems();
    
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [code, setCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const showModal = () => {
        setIsOpen(true);
        setIsLoading(false);
    };

    const hideModal = () => {
        setHasError(false);
        setCode("");
        setErrorMessage("");
        setIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCode(value);
        setErrorMessage("");
    }

     const handleSubmit = (e) => {
        setIsLoading(true);
    }

    return ( 
        <Layout title="Orders" description="Orders List" >
            <div >    
                <Modal show={isOpen} onHide={hideModal}>
                    <Modal.Body>
                    <form>
                        <div className={hasError ? 'form-group text-danger' : 'form-group'}>
                            <label htmlFor="code">Enter Code {hasError}</label>
                            <input name="code" className={hasError ? 'form-control is-invalid' : 'form-control'} value={code} id="code" onChange={e => handleChange(e)} />
                            <small>{errorMessage}</small>
                        </div>
                    </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={e => handleSubmit(e)}>
                        {isLoading ? (
                            <span>
                                <Spinner animation="border" />  Submit 
                            </span>
                        ) : (
                            <span>Submit</span>
                        )}

                        </button>
                        <button className="btn btn-secondary" onClick={hideModal}>
                            Cancel
                        </button>
                    </Modal.Footer>
        </Modal>           
                <div className="text-center pt-4 pb-4">
                    <h1>List of Orders</h1>
                </div>
                <OrdersGrid showModal={showModal}/>
            </div>
        </Layout>
     );
}
 
export default Order;