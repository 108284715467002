export const formatNumber = number => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
}

export const formatDate = datestr => {

	if(typeof datestr != 'string') {

		return "";
	}
    let ret = datestr.split("T")[0];
    ret = ret.split(" ")[0];

    return ret;
}

export const formatStatus = status => {

	
	const statuses = {
		"1" : "<span class='bold text-warning'>Pending</span>",
		"2" : "<span class='bold text-success'>Ordered</span>",
		"3" : "<span class='bold text-danger'>Rejected</span>"
	}
	
    return statuses[status];
}

export const formatOfficeTotal = (loc, item) => {

	if (loc) {
		let str = loc.toLowerCase() + "Total";
		console.log(str);
		console.log(item);
		return item[str];
	} else {

		return "";
	}
}

export const padZerosLeft = (num, size) => {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}