import React, { useContext, useState } from 'react';
import { CartContext } from '../../contexts/CartContext';

import CartItem from './CartItem';
import styles from './CartProducts.module.scss';

import auth from '../../services/auth';
import * as Services2 from '../../services/user.service';
import Spinner from 'react-bootstrap/Spinner'

const CartProducts = () => {

    const { cartItems } = useContext(CartContext);
    const [isLoading, setIsLoading] = useState(false);

    const saveCart = () => {
        setIsLoading(true);
        try  {
            let userinfo = auth.getUserInfo();

                let notesval = ""
                let notes = document.getElementById("notes")
                if(notes) {

                    notesval = notes.value
                }
                console.log('LOCAALSTORAGE', localStorage)
                let opt = {
                    id: userinfo.id,
                    cartItems: localStorage.getItem('cart'),
                    cartNotes: notesval
                }
                console.log(opt)
                Services2.user.patch(null, opt).then((res) => {         
                  setIsLoading(false);
                }).catch((err) => { setIsLoading(false);});
        } catch(e) {}
    }

    return ( 
        <div className={styles.p__container}>
            <div className="card card-body border-0" >

                {
                    cartItems.map(product =>  <CartItem key={product.id} product={product}/>)
                }

            </div>
            <div>
            <button class="btn btn-primary" onClick={saveCart}>

            {isLoading ? (
                    <span>
                        <Spinner animation="border" />  Save Cart 
                    </span>
                ) : (
                    <span>Save Cart</span>
                )}
          
            </button>
           

            </div>
        </div>

     );
}
 
export default CartProducts;