import React, { Component } from 'react';
import OrderItem from './OrderItem';
import styles from './OrdersGrid.module.scss';
import * as Services from '../../services/order.service';
import Pagination from "../common/Pagination";
import auth from '../../services/auth';

class OrdersGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allOrders: [],
            currentOrders: [],
            currentPage: 1,
            totalPages: null,
            pageLimit: 10,
            keyword: "",
            searchResults: [],
            orders: [],
            loading: true
        };
    }
    
    async componentDidMount() {

        let userinfo = auth.getUserInfo();

        const { data } = await Services.order.retrieveList(null, {officeCode: userinfo.officeCode}).then((res) => {
            return res;
        });

        this.setState((state) => {
            return Object.assign({}, state, 
            {
                orders: data
            });
        });
        this.fetchData();
    }

    fetchData = () => {
        const { orders, pageLimit, keyword } = this.state;

        const excludeColumns = ["id", "orderDate", "totalPrice", "createdAt", "createdBy", "updatedAt", "updatedBy"];
        const filteredData = orders.filter(product => {
            if (keyword == "") {
                return product;
            } else {
                return Object.keys(product).some(key =>
                    excludeColumns.includes(key) ? false : (product[key] ? product[key].toString().toLowerCase().includes(keyword) : false )
                );
            }
        });

        const currentOrders = filteredData.slice(0, 0 + pageLimit);

        this.setState((state) => {
            return Object.assign({}, state, 
            {
                allOrders: filteredData,
                currentOrders: currentOrders,
                currentPage: 1,
                loading: false
            });
        });
    }

    onPageChanged = data => {
        const { allOrders } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
    
        const offset = (currentPage - 1) * pageLimit;
        const currentOrders = allOrders.slice(offset, offset + pageLimit);
    
        this.setState({ currentPage, currentOrders, totalPages });
    };

    handleSearch = event => {
        const { name, value } = event.target;
        const keyword = value ? value.toLowerCase() : value;
         
		this.setState((state) => {
            return Object.assign({}, state, 
            {
                keyword: keyword,
                loading: true
            });
        });

        this.fetchData();
    };

    render() {
        const { keyword, loading, allOrders, currentOrders, currentPage, pageLimit } = this.state;
        const totalOrders = allOrders.length;
      
        const headerClass = [
            "text-dark py-2 pr-4 m-0",
            currentPage && totalOrders > 0 ? "border-gray border-right" : ""
        ].join(" ").trim();

        let count = 0;

        return ( 
            <div className={styles.p__container}>               
                <div className="row">
                    <div className="col-sm-4">
                        <div className="d-flex flex-row align-items-center">
                            <h5 className={headerClass}>
                                <strong className="text-secondary">{totalOrders}</strong>{" "}
                                Orders
                            </h5> 
                            {!loading && currentPage && totalOrders > 0 && (
                                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                                Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                                <span className="font-weight-bold">{totalOrders > 0 ? Math.ceil(totalOrders / pageLimit) : ''}</span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div>
                        {!loading && totalOrders > 0  && (<Pagination
                                totalRecords={ totalOrders }
                                pageLimit={pageLimit}
                                pageNeighbours={1}
                                onPageChanged={this.onPageChanged}
                            />)}
                        </div>
                    </div>
                    <div className="col-sm-4">
                         <div className="form-group" style={{"margin-bottom": "8px", "border":"1px solid #cccccc"}}>                            
                            <input type="text" name="keyword" value={keyword} placeholder="Search" className="form-control" onChange={(e)=>this.handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <div>
                    {!loading && currentOrders && currentOrders.length <= 0 && (
                        <h1 className="text-muted">No Records.</h1>
                    )}

                    {loading && (
                        <h1 className="text-muted">Loading.....</h1>
                    )}

                  
                    {!loading && currentOrders && currentOrders.length >= 1 && (
                        <table className="table">
                            <thead>
                                <tr style={{"background": "floralwhite"}}>
                                    <th width="10%"><b>Order#</b></th>
                                    <th width="15%"><b>User</b></th>
                                    <th width="10%"><b>Location</b></th>  
                                    <th width="10%"><b>Total Quantity</b></th>     
                                    <th width="15%"><b>Total Price</b></th>                                                      
                                    <th width="15%"><b>Order Date</b></th>
                                    <th width="10%"><b>Status</b></th>
                                    <th width="10%"><b>Notes</b></th>
                                    <th align="right"></th>
                                </tr>
                            </thead>

                            {currentOrders.map(order => (
                                <OrderItem 
                                    count={count++}
                                    key={order.id} order={order}/>
                            ))}
                        </table>
                    )}
                </div>
                <div className={styles.p__footer}>

                </div>
            </div>
        );
    }
}
export default OrdersGrid;