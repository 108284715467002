import React, { Component } from 'react';
import ProductItem from '../common/ProductItem';
import styles from './ProductsGrid.module.scss';
import * as Services from '../../services/product.service';
import Pagination from "../common/Pagination";

class ProductsGrid extends Component {

    constructor(props) {
		super(props);
		this.state = {
            allProducts: [],
            currentProducts: [],
            currentPage: 1,
            totalPages: null,
            pageLimit: 10,
            keyword: "",
            searchResults: [],
            products: [],
            loading: true,
        };
    }
    
	async componentDidMount() {

		const { data } = await Services.product.retrieveList(null).then((res) => {
            return res;
        });

        this.setState((state) => {
            return Object.assign({}, state, 
            {
                products: data
            });
        }); 

        this.fetchData();

         window.JcImagesTooltip.create('img.img-fluid', {
            offset: 10
        });
        
    }

    fetchData = () => {
        const { products, pageLimit, keyword } = this.state;

        const excludeColumns = ["id", "lastPurchasedDate", "price", "createdAt", "createdBy", "updatedAt", "updatedBy"];
        const filteredData = products.filter(product => {
            if (keyword == "") {
                return product;
            } else {
                return Object.keys(product).some(key =>
                    excludeColumns.includes(key) ? false : (product[key] ? product[key].toString().toLowerCase().includes(keyword) : false )
                );
            }
        });

        const currentProducts = filteredData.slice(0, 0 + pageLimit);

        this.setState((state) => {
            return Object.assign({}, state, 
            {
                allProducts: filteredData,
                currentProducts: currentProducts,
                currentPage: 1,
                loading: false
            });
        });

        window.JcImagesTooltip.create('img.img-fluid', {
            offset: 10
        });
    }

    onPageChanged = data => {
        const { allProducts } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
    
        const offset = (currentPage - 1) * pageLimit;
        const currentProducts = allProducts.slice(offset, offset + pageLimit);
    
        this.setState({ currentPage, currentProducts, totalPages });
    };

	handleSearch = event => {
        const { name, value } = event.target;
        const keyword = value ? value.toLowerCase() : value;
         
		this.setState((state) => {
            return Object.assign({}, state, 
            {
                keyword: keyword,
                loading: true
            });
        });

        this.fetchData();

         window.JcImagesTooltip.create('img.img-fluid', {
            offset: 10
        });
    };
    
    handleFavorite = event => { 
        const { products } = this.state;
        let rowId = event.target.getAttribute('data-rowid');
         
        let prod = products.filter(product => {
            if (product.id == rowId) {
                product.isFavorite = !product.isFavorite;
                Services.product.update(null, {...product}).then((res) => {
                    return res;
                });
            } 
            return product
        }); 

		this.setState((state) => {
            return Object.assign({}, state, 
            {
                products: prod
            });
        });

        window.JcImagesTooltip.create('img.img-fluid', {
            offset: 10
        });
    };

    render() {
        const { keyword, loading, allProducts, currentProducts, currentPage, pageLimit } = this.state;
        const totalProducts = allProducts.length;
      
        const headerClass = [
            "text-dark py-2 pr-4 m-0",
            currentPage && totalProducts > 0 ? "border-gray border-right" : ""
        ].join(" ").trim();

        let count = 0;
        return ( 
            <div className={styles.p__container}>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="d-flex flex-row align-items-center">
                            <h5 className={headerClass}>
                                <strong className="text-secondary">{totalProducts}</strong>{" "}
                                ITEMS
                            </h5>
                            {!loading && currentPage && totalProducts > 0 && (
                                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                                Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                                <span className="font-weight-bold">{Math.ceil(totalProducts / pageLimit)}</span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div>
                        {!loading && (<Pagination
                                totalRecords={ totalProducts }
                                pageLimit={pageLimit}
                                pageNeighbours={1}
                                onPageChanged={this.onPageChanged}
                            />)}
                        </div>
                    </div>
                    <div className="col-sm-4">
                         <div className="form-group" style={{"margin-bottom": "8px","border":"1px solid #cccccc"}}>                            
                            <input type="text" name="keyword" value={keyword} placeholder="Search Item" className="form-control" onChange={(e)=>this.handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <div className={styles.p__grid}>
                    {!loading && currentProducts && currentProducts.length <= 0 && (
                        <h1 className="text-muted">No Records.</h1>
                    )}

                    {loading && (
                        <h1 className="text-muted">Loading.....</h1>
                    )}

                    {!loading && currentProducts && currentProducts.length >= 1 && (
                        currentProducts.map(product => (
                            <ProductItem 
                                count={count++}
                                handleFavorite={this.handleFavorite}
                                key={product.id} product={product}/>
                        ))
                    )}
                    {!loading && currentProducts && currentProducts.length >= 1 && (
                    <div className="col-sm-12" style={{ "border-bottom": "1px solid rgba(0, 0, 0, 0.125)", "border-left": "1px solid rgba(0, 0, 0, 0.125)", "border-right": "1px solid rgba(0, 0, 0, 0.125)", background: count%2 == 0 ? "floralwhite" : "white"}}>
                        &nbsp;
                    </div>
                    )}
                </div>
                <div className={styles.p__footer}>

                </div>
            </div>
        );
    }
}
export default ProductsGrid;