import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { CartContext } from '../../contexts/CartContext';
import {CartIcon} from '../icons';
import styles from './header.module.scss';
import auth from '../../services/auth';

const Header = () => {

    const {itemCount} = useContext(CartContext);
    const {notes} = useContext(CartContext);
    const pathname = window.location.pathname;

    let [userinfo, setUserinfo] = useState({firstName: "", lastName: ""});

    if (!auth.isAuthenticated()) {

        document.location = "/login"
    }

    userinfo = auth.getUserInfo();

    const handleLogout = () =>  {

    	auth.clearAllAppStorage();
    	document.location = "/login";
    }

    return ( 
    	<div>
	    	<div className={styles.headerwrapper + " col-sm-12 text-left pt-2 headpanel1"} >
		    	<div className={styles.logo + "col-sm-6"}  style={{"max-height": "60px"}}>
		    		<img src={"logo2.png"} width={"200px"} /> <span className={styles.headertext}>Staff Ordering</span>   		              
		    	</div>	
		    	<div className={"cols-sm-6 text-right position: absolute;"}>
		    		<span className={styles.loggedin}>{userinfo.firstName} {userinfo.lastName}</span> | <a onClick={handleLogout} href="#">Logout</a>
		    	</div>   	
	        </div>
	        <div className="col-sm-12">
	        	<div className={styles.headerlinks + " col-sm-12"} >
			        <header className={styles.header + ""} >  
	                    <Link to='/' className={pathname == "/" ? styles.active : "inactive"} >Dashboard</Link> 
			        	<Link to='/orders' className={pathname == "/orders" ? styles.active : "inactive"}> Orders</Link>     	       	
			            {/* <Link to='/items' className={pathname == "/items" ? styles.active : "inactive"}>Items</Link> */}
			            <Link to='/inventory' className={pathname == "/inventory" ? styles.active : "inactive"}>Inventory</Link>
			            <Link to='/cart' className={pathname == "/cart" ? styles.active : "inactive"} style={itemCount > 0 ? {color: "blue"} : {color: "auto"}} > <CartIcon/> Cart ({itemCount})</Link>
			        </header>
		        </div>
		    </div>
	    </div>
     );
}
 
export default Header;
