import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Dashboard from '../components/dashboard';
import About from '../components/About';
import NotFound from '../components/NotFound';
import Cart from "../components/cart";
import Order from "../components/order";
import Inventory from "../components/inventory";
import Item from '../components/item';
import Login from "../components/login";

const Routes = () => {
  return (
    <Router>
        <Switch>
          <Route path="/login" component={Login}/>
          <Route path="/inventory" component={Inventory} />
          <Route path="/items" component={Item} />  
          <Route path="/about" component={About} />
          <Route exact path="/" component={Dashboard}/>
          <Route path="/orders" component={Order} />          
          <Route path="/cart" component={Cart} />
          <Route component={NotFound} />
        </Switch>
    </Router>
  );
}

export default Routes;