import React, { useContext, useState } from 'react';
import Layout from '../Layout';
import { formatNumber } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import InventoryGrid from './InventoryGrid';
import CurrentCartItems from '../common/CurrentCartItems';

const Inventory = () => {

    CurrentCartItems()

    return ( 
        <Layout title="Inventory" description="This is the Inventory page" >
            <div >
                <div className="text-center pt-4 pb-4">
                    <h1>Office Available Items</h1>
                </div>
                <InventoryGrid />
            </div>
        </Layout>
     );
}
 
export default Inventory;