import React from "react";
import Api from '../utils/Http'

import { Link } from "react-router-dom";

export const product = {
    retrieveList: (store, options = {}) => {
        let limit = options.limit ? options.limit : 10;
        let path = "/products?&filter[order]=isFavorite DESC";

        if (options.isFavorite) {
            path += "&filter[where][isFavorite]=" + options.isFavorite;
        }

        /*if (options.office) {
            path += "?filter[where][office]=" + options.office;
        }*/
        if (options.office && options.office !== "") {
            let loc = options.office ? options.office.toLowerCase() : options.office;

            path += "&filter[where][" + loc + "Total][gt]=0";
        }

        return Api.get(path);
    },

    update: (store, options = {}) => {
        return Api.put('/products', options);
    }

}