import React, { useContext } from 'react';
import auth from '../../services/auth';
import * as Services from '../../services/user.service';

const CurrentCartItems = async () => {

    let userinfo = auth.getUserInfo();
    console.log(userinfo);

    await Services.user.get(null, {id: userinfo.id}).then((res) => {

        if (res.data && res.data.id) {
  
          let cartItems = res.data.cartItems;
          let localCart = localStorage.getItem('cart');

          if (cartItems !== localCart) {

            localStorage.setItem('cart', (cartItems ? cartItems: [])); 
            document.location.reload();
          }

        }
    }).catch((err) => {
        
        console.log('Update Cart Error: ', err)
    })
}
 
export default CurrentCartItems;
