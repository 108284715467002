import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import { formatNumber, formatOfficeTotal, formatDate } from '../../helpers/utils';

const ProductItem = ({product, handleFavorite, count, location}) => {

    const { addProduct, cartItems, increase } = useContext(CartContext);

    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }

    return ( 
        <div className="col-sm-12 text-left pt-2 pb-2" style={{ "border-top": "1px solid rgba(0, 0, 0, 0.125)", "border-left": "1px solid rgba(0, 0, 0, 0.125)", "border-right": "1px solid rgba(0, 0, 0, 0.125)", background: count%2 == 0 ? "floralwhite" : "white"}}>
            <div className="row">
                <div className="col-sm-4 text-left">
                    <div className="text-left">
                        <img onClick={handleFavorite} data-rowid={product.id}
                            src={product.isFavorite ? "heartred.png" : "heart.png"} width={"36px"} /> 
                        <img style={{display: "inline", margin: "0 10px", maxHeight: "60px"}} className="img-fluid" 
                            src={product.photo ? product.photo : "/img/rxdefault.jpg" + '?v=' + product.id} data-src={product.photo ? product.photo : "/img/rxdefault.jpg" + '?v=' + product.id}alt=""/>
                    </div>
                    <div className="text-left">
                        <b>Item Code:</b>{product.itemCode}
                     </div>
                     <div className="text-left">
                        <b>Name:</b> {product.name}
                     </div>
                </div>
                <div className="col-sm-4 text-left">
                    <div className="text-left">
                        <b>Manufacturer Code:</b> {product.manufacturerCode}
                    </div>
                    <div className="text-left">
                        <b>Name:</b> {product.manufacturer}
                    </div>
                     <div className="text-left">
                        <b>UOM:</b> {product.uom}
                    </div>
                    <div className="text-left">
                        <b>Price:</b> {product.price}
                    </div>
                     <div className="text-left">
                       <b>Last Purchased Date:</b> {formatDate(product.lastPurchasedDate)}
                    </div>
                </div>  
                <div className="col-sm-4 text-left">
                    <div className="text-left">
                        <b>Category:</b> {product.category}
                    </div>               
                    <div className="text-left">
                        <b>Tags:</b>
                    </div>
                    <div className="text-left pl-4">
                       {product.tag1}
                    </div>
                     <div className="text-left pl-4">
                       {product.tag2}
                    </div>
                    <div className="text-left pl-4">
                       {product.tag3}
                    </div>
                    <div className="text-left">
                       <b>Supplier:</b> {product.supplier}
                    </div>
                    <div className="text-left">
                       {location  && <b>Available Quantity:</b> } {formatOfficeTotal(location, product)}
                    </div>
                    <div className="text-right">
                        {
                            isInCart(product) && 
                            <button 
                            onClick={() => increase(product)}
                            className="btn btn-outline-primary btn-sm">Add more</button>
                        }

                        {
                            !isInCart(product) && 
                            <button 
                            onClick={() => addProduct(product)}
                            className="btn btn-primary btn-sm">Add to cart</button>
                        }
                        
                    </div> 
                </div>          
            </div>           
        </div>
     );
}
 
export default ProductItem;
