import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from '../../contexts/CartContext';
import { formatNumber, formatDate, formatStatus, padZerosLeft } from '../../helpers/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import OrdersGrid from './OrdersGrid';
import styles from './OrdersGrid.module.scss';
import { product } from '../../services/product.service';
import EllipsisText from "react-ellipsis-text";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner'

import * as Services from '../../services/order.service';


const OrderItem = ({order, count}) => {    

     let isLoadingArr = order.orderDetail.map(item => {

        return false
     })

    const [isLoading, setIsLoading] = useState(isLoadingArr);

    const [togglerow, setTogglerow] = useState("");
    const handleToggleRow = (orderNo) => {
        if (togglerow == orderNo) {
            setTogglerow("");
        } else {
            setTogglerow(orderNo);
        }            
    }   

     let arrivedArr = order.orderDetail.map(item => {

        if (item.arrivedBy) {
            return new Date(item.arrivedBy)
        } else {
            return ""
        }
     }) ;

    const [arrivedBy, setArrivedBy] = useState(arrivedArr);

    const handleArrivedBy = (idx, val) => {

        let a = arrivedBy
        a[idx] = val

        setArrivedBy(a.map(i=>{return i})) 
    }

    let unitsArr = order.orderDetail.map(item => {

        return item.unitsArrived
     }) ;

    const [unitsArrived, setUnitsArrived] = useState(unitsArr);

    const handleUnitsArrived = (idx, e) => {

        let a = unitsArrived
        a[idx] = e.target.value

        setUnitsArrived(a.map(i=>{return i}))     
    }

    const handleSubmit = async (idx, item) => {

        let l = isLoading
        l[idx] = true

        setIsLoading(l.map(i=>{return i})) 

        let a = arrivedBy
        let u = unitsArrived

        let arrived_by = a[idx]
        let units_arrived = u[idx]      

         if (!arrived_by) {

            arrived_by = null;
         } 

        let options = {
            id: item.id,
            unitsArrived: units_arrived,
            arrivedBy: arrived_by
        }

        const { data } = await Services.order.patchItem(null, options).then((res) => {
            return res;
        });

        l[idx] = false
        setIsLoading(l.map(i=>{return i})) 

    }

    return ( 

        <tbody>                                     
            <tr>                                
                <td width="10%">{padZerosLeft(order.orderNo, 6)}</td>            
                <td width="15%">{order.user}</td> 
                <td width="10%">{order.officeCode}</td>
                <td width="10%">{order.totalQuantity}</td>
                <td width="15%">{formatNumber(order.totalPrice)}</td>
                <td width="15%">{formatDate(order.orderDate)}</td>
                <td width="10%" dangerouslySetInnerHTML={{__html: formatStatus(order.status)}}></td>
                <td width="10%" style={{ cursor: 'pointer' }}>{order.notes ? <EllipsisText tooltip={order.notes} title={order.notes} text={order.notes} length={"15"} /> : ''}</td>
                <td align="right" style={{ paddingTop: '1px' }} >
                    <button className="btn btn-xs" alt="Toggle Items"  title="Toggle Items" 
                        onClick={() => handleToggleRow(order.orderNo)}
                    >
                        <i className={(togglerow == order.orderNo) ? "fa fa-compress" : "fa fa-expand"} onClick={() => handleToggleRow(order.orderNo)} />
                    </button>
                </td>
            </tr>
            <tr className={(togglerow == order.orderNo) ? "" : styles.hidden}>
                <td colspan="8" style={{ padding: '0px' }}>
                    <table className="table">   
                        <tbody style={{background: "floralwhite"}}>
                            <tr style={{background: "lightpink"}}>
                                <th><b>Item#</b></th>
                                <th><b>Item Name</b></th>
                                <th><b>Manufacturer</b></th>
                                <th><b>Quantity</b></th>
                                <th><b>Price</b></th>    
                                <th width="100"><b>Arrived <br />By</b></th>
                                <th><b>Units <br />Arrived</b></th>
                                <th></th>
                            </tr>
                            
                            {                             
                             order.orderDetail.length > 0 ? 
                                order.orderDetail.map((item, idx) =>
                                    {
                                        let {product} = item;                               
                                        
                                        return <tr key={item.id}>                                
                                            <td>{product.itemCode}</td>
                                            <td>{product.name}</td>
                                            <td>{product.manufacturerCode}</td>
                                            <td className="text-right">{item.quantity}</td>    
                                            <td className="text-right">{formatNumber(item.totalPrice)}</td>
                                            <td><DatePicker style={{"max-width": "50px"}} selected={arrivedBy[idx]} value={arrivedBy[idx]} onChange={date => handleArrivedBy(idx, date)} /></td>
                                            <td><input style={{"max-width": "84px"}} type="text" name="unitsArrived" value={unitsArrived[idx]} onChange={e => handleUnitsArrived(idx, e)} /> </td>
                                            <td><button className="btn btn-primary" style={{padding: '0px', height: '30px', width : '50px'}} onClick={e => handleSubmit(idx, item, e)}>
                                            {isLoading[idx] ? (
                                                <span>
                                                    <Spinner animation="border" />  Save 
                                                </span>
                                            ) : (
                                                <span>Save</span>
                                            )}

                                            </button></td>                                   
                                        </tr>
                                    }
                                )

                            : 

                            <tr><td colspan="7">No items found.</td></tr>
                            }
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    );
}
 
export default OrderItem;
