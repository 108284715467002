import React from "react";
import Api from '../utils/Http'

import { Link } from "react-router-dom";

export const user = {
    retrieveList: (store, options = {}) => {
        let path = "/appusers";
        return Api.get(path);
    },

    login: (store, options = {}) => {
        return Api.post('/Users/login', options);
    },

    get: (store, options = {}) => {
        return Api.get('/Appusers/' + options.id, options);
    },
    
    findOne: (store, options = {}) => {
        let path = "/Appusers";

        if (options.userCode) {
            path += "?filter[where][userCode]=" + options.userCode;
        }

        return Api.get(path);
    },

    patch: (store, options = {}) => {

        return Api.patch('/Appusers/' + options.id, options);
    }



}