import React, { useContext, useState } from 'react';
import Layout from '../Layout';
import CartProducts from './CartProducts';
import { CartContext } from '../../contexts/CartContext';
import { formatNumber } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import auth from '../../services/auth';
import Spinner from 'react-bootstrap/Spinner'
import * as Services from '../../services/user.service';
import CurrentCartItems from '../common/CurrentCartItems';

const Cart = () => {

   // CurrentCartItems();

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [code, setCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { setUser, total, cartItems, itemCount, clearCart, checkout, handleCheckout, notes, setNotes } = useContext(CartContext);

    const showModal = () => {
        setIsOpen(true);
        setIsLoading(false);
    };

    const hideModal = () => {
        setHasError(false);
        setCode("");
        setErrorMessage("");
        setIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCode(value);
        setErrorMessage("");
    }

    const handleSubmit = (e) => {
        setIsLoading(true);
        if (code.length > 0) {
            Services.user.findOne(null, {userCode: code}).then((res) => {
                const {data} = res;

                if (data.length) {
                    setUser({
                        user: data[0].userCode + " (" + data[0].firstName + " " + data[0].lastName + ") "
                    })
                    hideModal();
                    handleCheckout();
                } else {
                    setErrorMessage("Incorrect user code.");
                    setHasError(true);
                    setIsLoading(false);
                }
            });
        } else {
            setErrorMessage("User code is required.");
            setHasError(true);
            setIsLoading(false);
        }
    };
    
    return ( 
        <Layout title="Cart" description="This is the Cart page" >
            <div >
                <div className="text-center">
                    <h1>Cart</h1>
                </div>
                <Modal show={isOpen} onHide={hideModal}>
                    <Modal.Body>
                    <form>
                        <div className={hasError ? 'form-group text-danger' : 'form-group'}>
                            <label htmlFor="code">Enter Code {hasError}</label>
                            <input name="code" className={hasError ? 'form-control is-invalid' : 'form-control'} value={code} id="code" onChange={e => handleChange(e)} />
                            <small>{errorMessage}</small>
                        </div>
                    </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={e => handleSubmit(e)}>
                        {isLoading ? (
                            <span>
                                <Spinner animation="border" />  Submit 
                            </span>
                        ) : (
                            <span>Submit</span>
                        )}

                        </button>
                        <button className="btn btn-secondary" onClick={hideModal}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <div className="row no-gutters justify-content-center">
                    <div className="col-sm-9 p-3">
                        {
                            cartItems.length > 0 ?
                            <CartProducts/> :
                            <div className="p-3 text-center text-muted">
                                Your cart is empty
                            </div>
                        }

                        { checkout && cartItems.length < 1 &&
                            <div className="p-3 text-center text-success">
                                <p>Checkout successfull</p>
                                <Link to="/" className="btn btn-outline-success btn-sm">BUY MORE</Link>
                            </div>
                        }
                    </div>
                    {
                        cartItems.length > 0 && 
                        <div className="col-sm-3 p-3">
                            <div className="card card-body">
                                <p className="mb-1">Total Items</p>
                                <h4 className=" mb-3 txt-right">{itemCount}</h4>
                                <p className="mb-1">Notes</p>
                                <textarea name="notes" className="form-control" value={notes} id="notes" onChange={(e) => setNotes({notes: e.target.value})}  style={{"border":"1px solid #cccccc"}} />
                                <hr className="my-4"/>
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary mb-2" onClick={showModal}>CONFIRM</button>
                                    <button type="button" className="btn btn-outlineprimary btn-sm" onClick={(e) => setNotes({notes: ""})}>CLEAR</button>
                                </div>
                            </div>
                        </div>
                    }
                    
                </div>
            </div>
        </Layout>
     );
}
 
export default Cart;