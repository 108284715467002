import React from 'react';
import Layout from '../Layout';

import ProductsGrid from '../item/ProductsGrid';
import CurrentCartItems from '../common/CurrentCartItems';

const Store = () => {
    
    CurrentCartItems();

    return ( 
        <Layout title="Items" description="Items List" >
            <div >
                <div className="text-center pt-4 pb-4">
                    <h1>Items</h1>
                </div>
                <ProductsGrid/>
            </div>
        </Layout>
     );
}
 
export default Store;