import * as Services from '../services/order.service';
import auth from '../services/auth';
import * as Services2 from '../services/user.service';

const Storage = (cartItems) => {
    localStorage.setItem('cart', JSON.stringify(cartItems.length > 0 ? cartItems: []));
    if (!cartItems.length) {

        try  {
            let userinfo = auth.getUserInfo();
           
                let opt = {
                    id: userinfo.id,
                    cartItems: localStorage.getItem('cart')
                }
                console.log(opt)
                Services2.user.patch(null, opt).then((res) => {         
                  
                }).catch((err) => { });
        } catch(e) {}
    }
}

export const sumItems = (cartItems, state)=> {
    Storage(cartItems);

    let itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
    let total = cartItems.reduce((total, product) => total + product.price * product.quantity, 0).toFixed(2);
    let count = 0;
    let cartProducts = cartItems.reduce((obj, item) => {
        obj[count++] = {
            name: item.name,
            productId: item.id,
            quantity: item.quantity,
            totalPrice: (item.price * item.quantity).toFixed(2),
            itemCode: item.itemCode,
            updatedAt: new Date()
        };
        return Object.values(obj);
    }, {})
    
    return { itemCount, total, cartProducts }
}

export const CartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            if (!state.cartItems.find(item => item.id === action.payload.id)) {
                state.cartItems.push({
                    ...action.payload,
                    quantity: 1,
                    totalPrice: state.total
                })
            } 

            return {
                ...state,
                ...sumItems(state.cartItems, state),
                cartItems: [...state.cartItems],
                totalPrice: state.total
            }
        case "REMOVE_ITEM":
            return {
                ...state,
                ...sumItems(state.cartItems.filter(item => item.id !== action.payload.id), state),
                cartItems: [...state.cartItems.filter(item => item.id !== action.payload.id)],
                totalPrice: state.total
            }
        case "INCREASE":
            state.cartItems[state.cartItems.findIndex(item => item.id === action.payload.id)].quantity++
            return {
                ...state,
                ...sumItems(state.cartItems, state),
                cartItems: [...state.cartItems],
                totalPrice: state.total
            }
        case "DECREASE":
            state.cartItems[state.cartItems.findIndex(item => item.id === action.payload.id)].quantity--
            return {
                ...state,
                ...sumItems(state.cartItems, state),
                cartItems: [...state.cartItems],
                totalPrice: state.total
            }
        case "CHECKOUT":
            let userinfo = auth.getUserInfo();
            let loc = '';
            switch (userinfo.officeCode) {
                case 'CCD': loc = "Cambridge Children's Dentistry"; break;
                case 'APD': loc = "Arlington Pediatric Dental"; break;
                case 'LKD': loc = "Lynn Kids Dental"; break;
                default: loc = ""; break;
            }

            let order = {
                user: state.user,
                notes: state.notes,
                totalPrice: state.total,
                totalQuantity: state.itemCount,
                officeCode: userinfo.officeCode,
                orderDate: new Date(),
                updatedAt: new Date(),
                orderDetail: state.cartProducts
            }
 
            Services.order.add(null, order).then((res) => {
                const {data} = res;
                order.id = data.id;

                Services.order.sendEmail(null, {cartItems: order, location: loc, notes: data.notes}).then((res) => {
                    //success
                }).catch(err=>{
                    console.log(err);
                });
            });

            return {
                cartItems: [],
                checkout: true,
                notes: '',
                user: '',
                ...sumItems([])
            }
        case "SET_USER":
            return {
                ...state,
                user: action.payload && action.payload.user ? action.payload.user : ''
            }
        case "SET_NOTES":
            return {
                ...state,
                notes: action.payload && action.payload.notes ? action.payload.notes : ''
            }
        case "CLEAR":
                return {
                    cartItems: [],
                    ...sumItems([])
                }
        default:
            return state

    }
}