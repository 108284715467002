import React, { Component } from 'react';
import ProductItem from './ProductItem';
import styles from './InventoryGrid.module.scss';
import * as InventoryServices from '../../services/inventory.service';
import * as ProductServices from '../../services/product.service';
import Pagination from "../common/Pagination";
import auth from '../../services/auth';

class InventoryGrid extends Component {

    constructor(props) {
		super(props);
		this.state = {
            allStocks: [],
            currentStocks: [],
            currentPage: 1,
            totalPages: null,
            pageLimit: 10,
            keyword: "",
            searchResults: [],
            stocks: [],
            loading: true,
            office: "CCD",
            location: 'Cambridge Children\'s Dentistry'
        };
    }
    
	async componentDidMount() {

        let userinfo = auth.getUserInfo();
        let loc = 'Cambridge Children\'s Dentistry';
        let offc = 'CCD';
        offc = userinfo.officeCode;

        switch (userinfo.officeCode) {
            case 'CCD': loc = 'Cambridge Children\'s Dentistry'; break;
            case 'APD': loc = 'Arlington Pediatric Dental'; break;
            case 'LKD': loc = 'Lynn Kids Dental'; break;
            default: loc = loc; break;
        }

        this.setState((state) => {
            return Object.assign({}, state, 
            {
                office: offc,
                location: loc
            });
        });

        const { data } = await InventoryServices.inventory.retrieveList(null, {location: offc})
                                        .then((res) => {
                                            return res;
                                        });
    
        this.setState((state) => {
            return Object.assign({}, state, 
            {
                stocks: data
            });
        }); 

        this.fetchData(this.state.keyword);
    }

    fetchData = (keyword) => {
        const { stocks, pageLimit } = this.state;
        
        const excludeColumns = ["id", "lastPurchasedDate", "price", "createdAt", "createdBy", "updatedAt", "updatedBy"];
        const filteredData = stocks.filter(stock => {
            const {product} = stock;
            if (keyword == "") {
                return stock;
            } else {
                return Object.keys(product).some(key =>
                    excludeColumns.includes(key) ? false : (product[key] ? product[key].toString().toLowerCase().includes(keyword) : false )
                );
            }
        });

        const currentStocks = filteredData.slice(0, 0 + pageLimit);

        this.setState((state) => {
            return Object.assign({}, state, 
            {
                allStocks: filteredData,
                currentStocks: currentStocks,
                currentPage: 1,
                loading: false
            });
        });

        window.JcImagesTooltip.create('img.img-fluid', {
            offset: 10
        });
    }
    
    onPageChanged = data => {
        const { allStocks } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
    
        const offset = (currentPage - 1) * pageLimit;
        const currentStocks = allStocks.slice(offset, offset + pageLimit);
    
        this.setState({ currentPage, currentStocks, totalPages });
    };

	handleSearch = event => {
        const { name, value } = event.target;
        const keyword = value ? value.toLowerCase() : value;
         
		this.setState((state) => {
            return Object.assign({}, state, 
            {
                keyword: keyword,
                loading: true
            });
        });

        this.fetchData(keyword);
    };

    handleFavorite = event => { 
        const { stocks } = this.state;
        let rowId = event.target.getAttribute('data-rowid');
         
        let prod = stocks.filter(stock => {
            const {product} = stock;
            if (product.id == rowId) {
                product.isFavorite = !product.isFavorite;
                ProductServices.product.update(null, {...product}).then((res) => {
                    return res;
                });
            } 
            return stock
        }); 
		this.setState((state) => {
            return Object.assign({}, state, 
            {
                stocks: prod
            });
        });
    };
    
    render() {
        const { keyword, loading, allStocks, currentStocks, currentPage, pageLimit, office, location } = this.state;
        const totalStocks = allStocks.length;
      
        const headerClass = [
            "text-dark py-2 pr-4 m-0",
            currentPage && totalStocks > 0 ? "border-gray border-right" : ""
        ].join(" ").trim();

        let count = 0;
        return ( 
            <div className={styles.p__container}>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="d-flex flex-row align-items-center">
                            <h5 className={headerClass}>
                                <strong className="text-secondary">{totalStocks}</strong>{" "}
                                ITEMS
                            </h5>
                            {!loading && currentPage && totalStocks > 0 && (
                                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                                Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                                <span className="font-weight-bold">{Math.ceil(totalStocks / pageLimit)}</span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div>
                        {!loading && (<Pagination
                                totalRecords={ totalStocks }
                                pageLimit={pageLimit}
                                pageNeighbours={1}
                                onPageChanged={this.onPageChanged}
                            />)}
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div><b>Location:</b> {location} </div>
                        <div className="form-group" style={{"margin-bottom": "8px","border":"1px solid #cccccc"}}>                            
                            <input type="text" name="keyword" value={keyword} placeholder="Search Item" className="form-control" onChange={(e)=>this.handleSearch(e)} />
                        </div>
                    </div>
                </div>
                <div className={styles.p__grid}>
                    {!loading && currentStocks && currentStocks.length <= 0 && (
                        <h1 className="text-muted">No Records.</h1>
                    )}

                    {loading && (
                        <h1 className="text-muted">Loading.....</h1>
                    )}

                    {!loading && currentStocks && currentStocks.length >= 1 && (
                        currentStocks.map(stock => (
                            <ProductItem 
                                count={count++}
                                handleFavorite={this.handleFavorite}
                                key={stock.id} stock={stock}
                                location={office}
                            />
                        ))
                    )}
                    {!loading && currentStocks && currentStocks.length >= 1 && (
                    <div className="col-sm-12" style={{ "border-bottom": "1px solid rgba(0, 0, 0, 0.125)", "border-left": "1px solid rgba(0, 0, 0, 0.125)", "border-right": "1px solid rgba(0, 0, 0, 0.125)", background: count%2 == 0 ? "floralwhite" : "white"}}>
                        &nbsp;
                    </div>
                    )}
                </div>
                <div className={styles.p__footer}>

                </div>
            </div>
        );
    }
}
export default InventoryGrid;