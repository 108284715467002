import React from 'react';

export const HomeIcon = ({width}) => <svg width={width}  fill="currentColor" viewBox="0 0 20 20"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>

export const MinusCircleIcon = ({width}) => <svg width={width} fill="currentColor" viewBox="0 0 20 20"><path d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" fillRule="evenodd"></path></svg>

export const PlusCircleIcon = ({width}) => <svg width={width}  fill="currentColor" viewBox="0 0 20 20"><path d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" fillRule="evenodd"></path></svg>

export const CartIcon = ({width}) => <svg width={width}  fill="currentColor" viewBox="0 0 20 20"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>

export const TrashIcon = ({width}) => <svg width={width} fill="currentColor" viewBox="0 0 20 20"><path d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" fillRule="evenodd"></path></svg>

export const GitHubIcon = ({width}) => <svg width={width} viewBox="0 0 16 16" fill="currentColor"><path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg>

export const HeartIcon = ({width}) => <svg width={width} viewBox="0 0 16 16" fill="#000000" stroke="none"><path d="M1290 4813 c-73 -7 -236 -46 -319 -75 -204 -72 -377 -183 -542 -347 -215 -216 -344 -459 -405 -766 -25 -125 -25 -405 -1 -529 48 -240 150 -464 292 -641 30 -38 510 -527 1065 -1087 776 -780 1021 -1022 1057 -1040 68 -33 189 -33 253 1 27 14 127 105 253 230 l209 206 16 -25 c36 -52 121 -147 173 -191 209 -176 455 -260 723 -246 438 23 801 312 932 742 26 83 28 103 28 270 1 210 -14 284 -89 444 -83 175 -197 305 -374 428 -2 2 45 54 106 115 242 249 367 479 430 794 24 124 24 404 -1 529 -61 306 -189 549 -406 766 -210 211 -437 335 -731 401 -138 30 -427 33 -559 5 -269 -57 -505 -176 -699 -351 -84 -76 -118 -93 -171 -81 -16 4 -65 39 -111 81 -272 245 -617 377 -974 372 -66 -1 -136 -3 -155 -5z m353 -178 c248 -37 482 -152 676 -330 96 -88 144 -110 241 -110 98 0 144 22 246 115 258 236 571 351 914 336 204 -8 365 -51 542 -144 292 -152 526 -433 626 -752 44 -139 57 -228 57 -390 0 -222 -39 -390 -135 -580 -70 -137 -137 -227 -283 -377 l-128 -131 -35 14 c-148 62 -415 78 -587 36 -361 -89 -650 -364 -752 -718 -50 -171 -49 -390 1 -560 14 -49 28 -96 30 -104 3 -9 -80 -101 -213 -237 -187 -190 -223 -223 -255 -229 -20 -3 -47 -3 -60 1 -30 10 -107 86 -1167 1155 -606 611 -914 929 -950 980 -105 150 -191 353 -222 530 -17 97 -17 343 0 440 98 551 537 979 1086 1059 96 14 262 12 368 -4z m2617 -2500 c154 -50 257 -113 361 -221 163 -171 239 -359 239 -589 0 -127 -16 -212 -59 -320 -81 -201 -234 -362 -432 -454 -202 -93 -424 -106 -631 -35 -514 175 -734 779 -454 1247 96 161 261 298 432 358 117 41 173 49 319 45 119 -3 152 -7 225 -31z"/><path d="M3955 1895 l-25 -24 0 -230 0 -230 -235 -3 c-227 -3 -235 -4 -257 -25 -30 -31 -30 -84 2 -118 l23 -25 231 -2 231 -3 3 -230 c2 -219 3 -230 24 -252 31 -33 87 -31 119 3 24 25 24 28 27 255 l3 229 228 0 c214 0 228 1 249 20 29 27 37 63 20 96 -23 50 -44 54 -280 54 l-217 0 -3 231 -3 231 -28 24 c-37 31 -81 31 -112 -1z"/></svg>